
.all-app {
  .all-app-tilelist {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(404px, max-content));
    justify-content: center;
    gap: 1.4vw;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    padding-bottom: 20px;

    .appExcerpt {
      max-width: 400px;
      background-color: var(--color-white);
      border-radius: 12px;
      display: flex;
      flex-direction: column;
      gap: 10px;
      height: 400px;
      margin-top: 40px;
      padding: 20px;

      .header {
        display: flex;
        align-items: center;
        gap: 20px;

        img {
          height: 35px;
        }
        .title {
          font-size: 24px;
          font-weight: 800;
          color: #4B4B4B;
          text-transform: capitalize;
        }
      }


      .description {
        .content {
          font-weight: 400;
          color: #4B4B4B;
          height: 120px;
          font-size: 14px;
          line-height: 150%;
        }

        .know-more-link {
          text-decoration-line: underline;
          color: #01080E;
          font-weight: 500;
          font-size: 16px;
        }
      }

      .tags {
        display: flex;
        flex-wrap: wrap;
        overflow: hidden;
        align-items: flex-start;
        height: 24.8px;
        gap: 10px;
        min-height: 3em;
      
        .item {
          padding: 4px 8px;
          background: #EDF1FC;
          border-radius: 4px;
          font-style: normal;
          font-size: 14px;
          font-weight: 400;
          line-height: 120%;
          color: #4B4B4B;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }

      .separation {
        padding-left: 32px;
        padding-right: 32px;
        border-bottom: 1px solid #E2E8F5;
        width:100%;
        margin-bottom: 20px;
      }

      .app-tile-buttons {
        width: 100%;
        display: flex;
        justify-content: center;

        a {
          width: 88%;
          text-decoration: none;
          color: #fff;
          display: flex;
          justify-content: center;
          gap: 5px;
        }
        .app-tile-button-go {
          min-height: 51px;
          width: 100%;
          padding: 11px 32px;
        }
        
        .app-tile-button-ask {
          min-height: 51px;
          width: 88%;
          border: 1px solid #22242D;
          background: var(--color-white);
          color: #22242D;
          padding: 11px 32px;
        }
    
        .app-tile-button-text {
          margin-top: 2px;
        }
        
        .app-tile-button-ask:hover {
          background: #eff0f073;
          box-shadow: none;
        }
        
        .app-tile-button-go:hover {
          box-shadow: none;
          background: #3260f5;
        }
      }
    }

    .all-app-filters {
      display: flex;
      flex-direction: row;
      // gap: 16px;
      // padding: 10px 0px;
      position: sticky;
      top: 0px;
      background-color: #F2F2F2;
      z-index: 2;
    
      button {
        font-family: 'Avenir';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        color: #242830;
        border: 1px solid #CCD4E3;
        border-radius : 12px;
        background-color: inherit;
        box-shadow: none;
        text-transform: none;
        height: 40px;
        padding: 8px 16px;

        .MuiButton-startIcon {
          margin-right: 0px;
        }
      }

      .activeFilterBtn {
        background-color: #456EFA;
        color: var(--color-white);
        text-transform: none;
        display: flex;
        flex-direction: row;
        gap: 10px;
      }

      .activeFilterBtn:hover {
        background-color: var(#456EFA);
      }
    }

    .skeleton-filters {
      max-width: 1255px;
      width: 100%;
    }

    .skeleton-tile {
      max-width: 404px;
      width: 100%;
    }
  
    button:hover {
      background-color: inherit;
      box-shadow: none;
    }
  }

  .all-app-tilelist > div:first-child {
    grid-column: 1 / -1;
  }
}

@media screen and (max-width: 1086px) {
  .all-app {
    justify-content: center;
    .all-app-tilelist {
      width: 83vw;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-left: 0;
      padding-right: 0;
      div.app-tile {
        width: 83vw;
      }
      .all-app-filters {
        button {
          height: 60px;
        }
      }
    }
  }
}

@media screen and (max-width: 700px) {
  .all-app {
    .all-app-tilelist {
      width: 90vw;
      div.app-tile {
        width: 90vw;  
      }
      .all-app-filters {
        flex-direction: column;
        button {
          height: 40px;
        }
      }
    }
  }
}