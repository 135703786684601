.program-page {
  .all-app-tilelist {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(404px, max-content));
    justify-content: center;
    gap: 1.4vw;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    padding-bottom: 20px;
    margin-top: 40px;

    .appExcerpt {
      max-width: 400px;
      background-color: var(--color-white);
      border-radius: 12px;
      display: flex;
      flex-direction: column;
      gap: 10px;
      height: 400px;
      margin-top: 40px;
      padding: 20px;

      .header {
        display: flex;
        align-items: center;
        gap: 20px;

        img {
          height: 35px;
        }
        .title {
          font-size: 24px;
          font-weight: 800;
          color: #4B4B4B;
          text-transform: capitalize;
        }
      }


      .description {
        .content {
          font-weight: 400;
          color: #4B4B4B;
          height: 120px;
          font-size: 14px;
          line-height: 150%;
        }

        .know-more-link {
          text-decoration-line: underline;
          color: #01080E;
          font-weight: 500;
          font-size: 16px;
        }
      }

      .tags {
        display: flex;
        flex-wrap: wrap;
        overflow: hidden;
        align-items: flex-start;
        height: 24.8px;
        gap: 10px;
        min-height: 3em;
      
        .item {
          padding: 4px 8px;
          background: #EDF1FC;
          border-radius: 4px;
          font-style: normal;
          font-size: 14px;
          font-weight: 400;
          line-height: 120%;
          color: #4B4B4B;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }

      .separation {
        padding-left: 32px;
        padding-right: 32px;
        border-bottom: 1px solid #E2E8F5;
        width:100%;
        margin-bottom: 20px;
      }

      .app-tile-buttons {
        width: 100%;
        display: flex;
        justify-content: center;

        a {
          width: 88%;
          text-decoration: none;
          color: #fff;
          display: flex;
          justify-content: center;
          gap: 5px;
        }
        .app-tile-button-go {
          min-height: 51px;
          width: 100%;
          padding: 11px 32px;
    
        }
        
        .app-tile-button-ask {
          min-height: 51px;
          width: 88%;
          border: 1px solid #22242D;
          background: var(--color-white);
          color: #22242D;
          padding: 11px 32px;
        }
    
        .app-tile-button-text {
          margin-top: 2px;
        }
        
        .app-tile-button-ask:hover {
          background: #eff0f073;
          box-shadow: none;
        }
        
        .app-tile-button-go:hover {
          box-shadow: none;
          background: #3260f5;
        }
      }
    }

    .skeleton-tile {
      max-width: 404px;
      width: 100%;
    }
  }

  .program-page-top-bar {
    display: flex;
    flex-direction: row;
    height: 339px;

    .program-page-top-bar-left {
      width: 50%;
      display: flex;
      height: inherit;

      .program-page-top-bar-text {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin: auto;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 511px;
        gap: 16px;
        height: inherit;

        .program-page-top-bar-name {
          color: #01080E;
          font-size: 24px;
          font-weight: 900;
        }

        .program-page-top-bar-description {
          color: #01080E;
          font-size: 14px;
          line-height: 150%;
          height: 224px;
        }
      }
    }
    
    .program-page-top-bar-image {
      width: 50%;

      img {
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
    }
  }
}

@media screen and (max-width: 1074px) {
  .program-page {
    .all-app-tilelist {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-left: 0;
      padding-right: 0;
      width: 90%;
    }
    .program-page-top-bar {
      flex-direction: column;
      height: 100%;

      .program-page-top-bar-left {
        height: 339px;
        width: 100%;
        .program-page-top-bar-text {
          width: 80%;
        }
      }

      .program-page-top-bar-image {
        width: 100%;
        height: 339px;
      }
    }
  }
}