
.app-page {
  background: #FFF;
  min-height: 100%;
  .app-page-banner {
    display: flex;
    flex-direction: row;
    height: 450px;
    border-bottom: 2px solid #EDF1FC;

    .app-page-banner-left-section {
      width: 50%;
      display: flex;

      .app-page-banner-text {
        display:flex;
        flex-direction: column;
        justify-content: center;
        margin: auto;
        width: 511px;
        gap: 40px;

        .app-page-title-logo {
          height: 24px;
          object-fit: contain;
          align-items: flex-start;
          width: fit-content;
          display: flex;
        }

        p {
          color: #01080E;
          line-height: 175%;
          margin-bottom: 0px;
        }

        .app-page-button-go {
          width: 50%;
          padding: 11px 32px;

          .link {
            color: white;
            gap: 10px;
            display: flex;
            align-items: center;

            span {
              padding-bottom: 2px;
            }
          }
        }
        
        .app-page-button-ask {
          width: 50%;
          border: 1px solid #22242D;
          background: var(--color-white);
          color: #22242D  ;
          padding: 11px 32px;
        }
    
        .app-page-button-text {
          margin-top: 4px;
        }
        
        .app-page-button-ask:hover {
          background: var(--color-white);
          box-shadow: none;
        }
        
        .app-page-button-go:hover {
          box-shadow: none;
          background: #456EFA;
        }
      }
    }

    .app-page-banner-right-section {
      background: #253038;
      width: 50%;
      align-items: center;
      justify-content: center;
      display: flex;
  
      .app-page-banner-image {    
        height: 100%;
        align-items: center;
        display: flex;
        .app-image {
          border-radius: 4px;
          background: lightgray 50% / cover no-repeat;
          box-shadow: 0px 12px 61px 0px rgba(0, 0, 0, 0.20), 0px 24px 37px 0px rgba(0, 0, 0, 0.30), 0px 0px 1.2px 0px rgba(0, 0, 0, 0.70);
          object-fit: cover;
          width: 510px;
          height: 287px;
        }
      }
    }
  }

  .app-description {
    justify-content: center;
    width: 70%;
    display: flex;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    padding-bottom: 30px;
    line-height: 175%;

    h1 {
      font-weight: 900;
      margin-bottom: 30px;
    }
  }

  .app-documentation {
    display: flex;
    flex-direction: row;
    .app-documentation-navigation {
      padding: 40px 32px 40px 32px;
      width: 20%;
      border-left: 2px solid #EDF1FC;
      font-size: 14px;
      line-height: 14px;

      .navigation-start {
        display: flex;
        flex-direction: column;
        gap: 4px;
        a {
          height: 30px;
          color: inherit;
        }
      }
    }

    .app-documentation-main {
      width: 60%;
      padding-top: 40px;

      .app-documentation-main-content {
        width: 617px;
        margin: auto;
        .app-documentation-main-subtitle {
          font-weight: 900;
          font-size: 32px;
          line-height: 32px;
          letter-spacing: 1%;
        }
        .app-documentation-main-subcontent {
          line-height: 24.5px;
          font-size: 14px;
        }
      }
    }


    .app-documentation-menu {
      padding: 40px 32px 40px 32px;
      width: 20%;
      display: flex;
      flex-direction: column;
      border-right: 2px solid #EDF1FC;

      .app-accordion::before {
        background-color: white;
      }
      
      div {
        margin: 0;
        min-height: unset;
        border: unset;
        box-shadow: none;
        padding-bottom: 0px;
        padding-top: 0px
      }

      .app-documentation-menu-section-title {
        display: flex;
        align-items: center;
        font-size: 16px;
        line-height: 16px;
        font-weight: 900;
        height: 40px;
      }

      .app-documentation-menu-subsection {
        justify-content: flex-start;
        width: 100%;
        color: #01080E;
        text-transform: none;
        height: 30px;
        font-size: 14px;
        line-height: 14px;
        text-align: left;
      }

      .app-documentation-menu-subsection:hover {
        background-color: var(--color-white);
      }

      .app-documentation-menu-subsection:focus {
        background-color: #F6F5C5;
      }
    }
  }
}

@media screen and (max-width: 1086px) {
  .app-page {
    .app-page-banner {
      flex-direction: column;
      gap: 40px;
      height: unset;

      .app-page-banner-left-section {
        padding-top: 20px;
        width: 100%;

        .app-page-banner-text {
          align-items: center;
          width: 70%;

          .app-page-button-go {
            width: unset;
          }

          .app-page-button-ask {
            width: unset;
          }
        }
      }
      .app-page-banner-right-section {
        width: 100%;
        
        .app-page-banner-image {
          max-width: 100%;
          .app-image {
            width: 80%;
            height: 80%;
            margin: auto;
          }
        }
      }
    }

    .app-description {
      margin-top: 60px;
    }
  }
}

@media screen and (max-width: 472px) {
  .app-page {
    .app-page-banner {
      .app-page-banner-left-section {
        .app-page-banner-text {
          width: 90%;
        }
      }
    }

    .app-description {
      width: 90%;
    }
  }
}