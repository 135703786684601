* {
  font-family: Avenir, Arial, Helvetica, sans-serif !important;
}

body {
  min-height: unset;
}

#app {
  height: calc(100vh - var(--topbar-height));
  margin-top: var(--topbar-height);
  scroll-behavior: smooth;
  overflow-x: auto;

  ::webkit-scrollbar {
    width: 0px;
    height: 0px;
  }

  button:focus {
    outline: none;  
  }
}

#app::-webkit-scrollbar {
  width: 5px;
}

#app::-webkit-scrollbar-track {
  background: #f1f1f1;
}

#app::-webkit-scrollbar-thumb {
  background: #525151;
  border-radius: 5px;
}

@media (max-width: 390px) {
  body {
    height: auto;
  }
}