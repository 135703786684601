.topBar {
  background-color: var(--color-black);
  height: var(--topbar-height);
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100;

  .logo-pdi {
    height: 28px;
    padding-left: 20px;
  }

  button {
    text-transform: capitalize;
    color: var(--color-white);
    font-weight: 500;
    font-family: Avenir;
    font-size: 16px;
  }

  .topBar-app-name {
    color: var(--color-white);
    font-weight: 500;
    font-family: Avenir;
  }

  .topBar-icon-white {
    color: #F3F5FA;
  }
  
  .reversed-icon {
    rotate: 180deg;
  }
}

.accountMenu-div {
  .accountMenu-item-title {
    padding-top: 1px;
    font-family: Avenir;
    color: var(--color-carbone);
  }
}

.menu-style {
  .MuiPaper-root {
    top: 72px !important;
    right: 0px;
    left: auto !important;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
  }
}

@media screen and (min-width: 450px) {
  .logo-pdi {
    padding-left: 20px;
    height: 30px;
  }
}

@media screen and (max-width: 1370px) {

  ul.ul-tab-mobile {
    width: 100%;
    margin-bottom: 0;
    a {
      background-color: var(--color-carbone);
    }
  }

  .logo-pdi {
    padding-left: 10px;
    height: 28px;
  }

  .topBar-dialog-content {
    background-color: var(--color-carbone);

    .topBar-menu-button {
      all: unset;
      width: 100%;
    }
    .MuiButton-endIcon {
      color: var(--color-white);
    }
  }

  .accountMenu-div {
    height: 58px;
    display: flex;
    justify-content: center;
    button {
      color: var(--color-white);
    }

    .accountMenu-button-text {
      padding-top: 3px;
    }
  }
}